/*eslint-disable*/
import settingServices from '@/modules/settings/services/settings'
export default {
  data() {
    return {
      vendorInfo: {},
      storeActive: {},
      myStores: [],
      default_store_id: ''
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      settingServices.getMerchantInfo().then(res => {
        this.default_store_id = res.data.default_store_id
        this.myStores = res.data.stores
        const selectedStoreIndex = res.data.stores.findIndex(store => {
          return store.id === res.data.default_store_id
        })
        this.storeActive = res.data.stores[selectedStoreIndex]
        this.storeActive.social_media_links = this.storeActive.social_media_links === null ? [{ selectSocial: '', link: '' }] : res.data.stores[selectedStoreIndex].social_media_links;
        if (this.storeActive.status !== 'completed') {
          if (this.storeActive.status === 'pending') {
            this.$router.push({ name: 'setting' })
            this.$store.commit('changeStoreStatus', 'pending')
            localStorage.setItem('storeStatus', 'pending')
            localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
          }
           else if (this.storeActive.status === 'processing') {
            this.$router.push({ name: 'processing' })
            this.$store.commit('changeStoreStatus', 'processing')
            localStorage.setItem('storeStatus', 'processing')
            localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
          }
          else if (this.storeActive.status === 'suspended') {
            this.$router.push({ name: 'processing' })
            this.$store.commit('changeStoreStatus', 'processing')
            localStorage.setItem('storeStatus', 'suspended')
            localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
          }
          else if (this.storeActive.status === 'deleted') {
            this.$router.push({ name: 'processing' })
            this.$store.commit('changeStoreStatus', 'deleted')
            localStorage.setItem('storeStatus', 'deleted')
            localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
          }
        } else {
          localStorage.setItem('vendorActiveStore', JSON.stringify(res.data.stores[selectedStoreIndex]))
          localStorage.setItem('storeStatus', 'completed')
          this.$store.commit('changeStoreStatus', 'completed')
        }
      })
    },
    changeDefaultStore(storeId) {
      settingServices.changeDefaultStore({ default_store_id: storeId }).then(() => {
        this.$router.push({ name: 'index' })
        this.getInfo()
      })
    }
  }
}
